<template>
	<div class="ranking-box">
		<div class="flex alcenter space">
			<div class="flex alcenter">
				<i class="iconfont icondata04 ft32" style="color:#FE8340;"></i>
				<span class="ml15 ft18 ftw600 cl-black">充值会员排行榜</span>
			</div>
			<div>
				<a-radio-group v-model="change_balance_time_type" @change="changeType" button-style="solid">
					<a-radio-button :value="3">
						本周
					</a-radio-button>
					<a-radio-button :value="1">
						本月
					</a-radio-button>
					<a-radio-button :value="2">
						本年度
					</a-radio-button>
					<a-radio-button :value="4">

						自定义
					</a-radio-button>
				</a-radio-group>
			</div>

		</div>
		<div class="selectTimer" v-if="show">
			<a-range-picker @change="change" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
		</div>
		<div class="mt24 wxb-table-gray">
			<!-- {{ balance_members_count }} -->
			<!-- pagination -->
			<a-table rowKey="member_id" :pagination="false" position="bottom" :columns="columns"
				:data-source="balance_rank_data" :loading="loading">
				<div class="flex alcenter center" slot="rank" slot-scope="rank,record,index">
					<img v-if="index + 1 == 1" style="width: 16px; height: 26px;"
						src="../../../assets/image/woyaoyou_num01@3x.png" />
					<img v-if="index + 1 == 2" style="width: 16px; height: 26px;"
						src="../../../assets/image/woyaoyou_num02@3x.png" />
					<img v-if="index + 1 == 3" style="width: 16px; height: 26px;"
						src="../../../assets/image/woyaoyou_num03@3x.png" />
					<span v-if="index + 1 > 3">{{ index + 1 }}</span>
				</div>
				<div class="flex alcenter center" slot="member" slot-scope="member,record">
					<img v-if="member.face != null" :src="member.face" class="member-index-face" />
					<img v-else src="../../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
					<div class="ml5" style="text-align: left;">
						<div>{{ member.nick_name }}</div>
						<div class="mt2">{{ member.mobile }}</div>
					</div>
				</div>
			</a-table>
			<div class="line">

			</div>
			<a-pagination @change="handlePageChange" v-model="current" :total="balance_members_count" show-less-items />
		</div>
	</div>
</template>

<script>

export default {
	props: {
		balance_time_type: {
			type: Number,
			default: 1
		},
		balance_members_count: {
			type: Number,
			default: 1
		},
		balance_rank_data: {
			type: Array,
			default: function () {
				return new Array
			}
		}
	},

	data() {

		return {
			current: 1,

			show: false,
			timerValue: '',
			begin_time1: '',
			end_time1: '',
			loading: false,
			change_balance_time_type: this.balance_time_type,
			columns: [
				{ title: '排名', dataIndex: 'rank', scopedSlots: { customRender: 'rank' }, align: 'center' },
				{ title: '会员', dataIndex: 'member', align: 'center', scopedSlots: { customRender: 'member' } },
				{ title: '实充金额', dataIndex: 'balance2', align: 'center', ellipsis: true },
				{ title: '赠送金额', dataIndex: 'give_balance', align: 'center', ellipsis: true },
			],
		}
	},
	methods: {
		handlePageChange(e) {
			// console.log(e);
			this.$emit('leftChange', e)
		},
		change(e) {
			let data = {
				begin_time1: e[0],
				end_time1: e[1],
				balance_time_type: this.change_balance_time_type,
				type: 1
			}
			this.begin_time1 = e[0]
			this.end_time1 = e[1]

			this.$emit('updateBalanceData', data)

		},
		changeType() {
			if (this.change_balance_time_type == 4) {
				this.show = true
				return
			}
			this.show = false
			let data = {
				balance_time_type: this.change_balance_time_type
			}
			this.$emit('updateBalanceData', data)
		},
	}
}
</script>

<style>
.member-index-face {
	width: 44px;
	height: 44px;
	border-radius: 44px;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
	border: 2px solid #FFFFFF;
}

.selectTimer {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}

.line {
	height: 20px;
	background: #fff;
}
</style>