<template>
	<div>
		<div class="flex">
			<div style="width: calc(100% - 220px);">
				<a-row :gutter="30">
					<a-col :span="8">
						<div class="count-box">
							<div class="flex alcenter space">
								<div class="flex alcenter">
									<i class="iconfont icondata01 ft32" style="color:#33A0FD;"></i>
									<span class="ft18 cl-main ftw600 ml15">会员余额储值 </span>
								</div>
								<!-- <div>
									<router-link to="/table">
										<i class="iconfont iconappoint_tab09 ft14 cl-blue"></i><span class="ft14 cl-blue ml10">更多</span>
									</router-link>
								</div> -->
							</div>
							<div class="mt24 text-center ft16 cl-info">
								累计
							</div>
							<div class="mt16 text-center cl-black ft30 ftw600">¥{{balance_recharge_data.total_recharge_balance}}</div>
							<div class="mt8 text-center ft12 cl-notice">(赠送金额：¥{{balance_recharge_data.total_recharge_give_balance}})</div>
							<!-- <div class="mt8 text-center ft12 cl-notice">(含赠送：¥{{balance_recharge_data.total_recharge_give_balance}})</div> -->
							<div class="mt24 flex">
								<div class="col2 text-center">
									<div class="flex alcenter center">
										<span class="ft16 cl-info">今日</span>
										<span class="ft18 ftw600 cl-black">¥{{balance_recharge_data.today_recharge_balance}}</span>
									</div>
									<div class="mt8 ft12 cl-notice">(赠送金额：¥{{balance_recharge_data.today_recharge_give_balance}})</div>
									<!-- <div class="mt8 ft12 cl-notice">(含赠送：¥{{balance_recharge_data.today_recharge_give_balance}})</div> -->
								</div>
								<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
									<div class="flex alcenter center">
										<span class="ft16 cl-info">本月</span>
										<span class="ft18 ftw600 cl-black">¥{{balance_recharge_data.this_month_recharge_balance}}</span>
									</div>
									<div class="mt8 ft12 cl-notice">(赠送金额：¥{{balance_recharge_data.this_month_recharge_give_balance}})</div>
									<!-- <div class="mt8 ft12 cl-notice">(含赠送：¥{{balance_recharge_data.this_month_recharge_give_balance}})</div> -->
								</div>
							</div>
						</div>
					</a-col>
					<!-- <a-col :span="8">
						<div class="count-box">
							<div class="flex alcenter space">
								<div class="flex alcenter">
									<i class="iconfont icondata02 ft32" style="color:#00C3A0;"></i>
									<span class="ft18 cl-main ftw600 ml15">会员余额核销</span>
								</div>
								<div>
									<router-link to="/table">
										<i class="iconfont iconappoint_tab09 ft14 cl-blue"></i><span class="ft14 cl-blue ml10">更多</span>
									</router-link>
								</div>
							</div>
							<div class="mt24 text-center ft16 cl-info">
								累计
							</div>
							<div class="mt16 text-center cl-black ft30 ftw600">¥{{balance_consume_data.total_consume_balance}}</div>
							<div class="flex" style="margin-top: 42px;">
								<div class="col2 text-center">
									<div class="ft16 cl-info">今日</div>
									<div class="ft18 mt8 ftw600 cl-black">¥{{balance_consume_data.today_consume_balance}}</div>
								</div>
								<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
									<div class="ft16 cl-info">本月</div>
									<div class="ft18 mt8 ftw600 cl-black">¥{{balance_consume_data.this_month_consume_balance}}</div>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="8">
						<div class="count-box">
							<div class="flex alcenter space">
								<div class="flex alcenter">
									<i class="iconfont icondata031 ft32" style="color:#FEC75A;"></i>
									<span class="ft18 cl-main ftw600 ml15">优惠券使用张数</span>
								</div>
								<div>
									<router-link to="/table">
										<i class="iconfont iconappoint_tab09 ft14 cl-blue"></i><span class="ft14 cl-blue ml10">更多</span>
									</router-link>
								</div>
							</div>
							
							<div class="mt24 text-center ft16 cl-info">
								累计
							</div>
							<div class="mt16 text-center cl-black ft30 ftw600">{{coupon_consume_data.total_consume_coupon}}</div>
							<div class="flex" style="margin-top: 42px;">
								<div class="col2 text-center">
									<div class="ft16 cl-info">今日</div>
									<div class="ft18 mt8 ftw600 cl-black">{{coupon_consume_data.today_consume_coupon}}</div>
								</div>
								<div class="col2 text-center" style="border-left: 1px solid #DCDFE6;">
									<div class="ft16 cl-info">本月</div>
									<div class="ft18 mt8 ftw600 cl-black">{{coupon_consume_data.this_month_consume_coupon}}</div>
								</div>
							</div>
						</div>
					</a-col> -->
				</a-row>
			</div>
			<!-- <div>
				<div class="kefu-box ml20">
					<div class="kefu-box-top">
						<div class="flex alcenter">
							<span class="ft14 ftw600 cl-main">专属客服：</span>
							<span class="ft14 ftw600 cl-theme ml5">{{kefu.name}}</span>
						</div>
						<div class="text-center mt16">
							<img v-if="kefu.qrcode != null" style="width: 100px; height: 100px; border:1px solid #F0F3F5;" :src="kefu.qrcode" />
							<img v-else style="width: 100px; height: 100px; border:1px solid #F0F3F5;" src="../../../assets/image/kefu.png" />
						</div>
						<div class="text-center ft14 cl-info mt12">
							微信扫一扫
						</div>
					</div>
					<div class="kefu-box-bottom">
						<img class="bg" src="../../../assets/image/woyaoyou_bg_phone@2x.png" />
						<div class="main">
							<div class="ft14 cl-w">客服手机号</div>
							<div class="mt12 flex alcenter">
								<i class="iconfont iconmobilephone ft20 cl-w"></i>
								<span class="ml10 ft20 cl-w">{{kefu.mobile}}</span>
							</div>
						</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			kefu:{
				type:Object,
				default:function(){
					return new Object
				}
			},
			balance_recharge_data:{
				type:Object,
				default:{
					total_recharge_balance:0,
					total_recharge_give_balance:0,
					today_recharge_balance:0,
					today_recharge_give_balance:0,
					this_month_recharge_balance:0,
					this_month_recharge_give_balance:0,
				}
			},
			// balance_consume_data:{
			// 	type:Object,
			// 	default:{
			// 		total_consume_balance:0,
			// 		today_consume_balance:0,
			// 		this_month_consume_balance:0
			// 	}
			// },
			// coupon_consume_data:{
			// 	type:Object,
			// 	default:{
			// 		total_consume_coupon:0,
			// 		today_consume_coupon:0,
			// 		this_month_consume_coupon:0,
			// 	}
			// }
		},
		data() {
			return {
				
			}
		}
	}
</script>

<style>
	.count-box {
		width: 100%;
		height: 260px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 24px;
	}

	.count-box div {
		line-height: 1;
	}

	.kefu-box {
		width: 220px;
		height: 260px;
		background: #FFFFFF;
		border-radius: 8px;
		overflow: hidden;
		line-height: 1;
	}

	.kefu-box-top {
		width: 100%;
		height: 188px;
		padding: 20px 20px 16px 20px;
	}

	.kefu-box-bottom {
		width: 100%;
		height: 72px;
		position: relative;
	}

	.kefu-box-bottom .main {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 72px;
		padding: 15px 20px;
	}

	.kefu-box-bottom .bg {
		width: 100%;
		height: 82px;
	}
</style>
