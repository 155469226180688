<template>
	<div>
		<a-row :gutter="30">
			<a-col :span="12">
				<!-- {{ balance_members_count }} -->
				<ranking-money @leftChange="leftChange" :balance_members_count="balance_members_count"
					:balance_time_type="changeSearch.balance_time_type" @updateBalanceData="updateBalanceData"
					:balance_rank_data="balance_rank_data"></ranking-money>
			</a-col>
			<a-col :span="12">
				<ranking-integral @leftChange="leftChange" :integral_members_count="integral_members_count"
					:integral_time_type="changeSearch.integral_time_type" @updateIntegralData="updateIntegralData"
					:integral_rank_data="integral_rank_data"></ranking-integral>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import RankingMoney from './RankingMoney.vue';
import RankingIntegral from './RankingIntegral.vue';
export default {

	props: {
		integral_members_count: {
			type: Number,
			default: 1
		},
		balance_members_count: {
			type: Number,
			default: 1
		},
		search: {
			type: Object,
			default: {
				balance_time_type: 1,
				integral_time_type: 1,
			}
		},
		balance_rank_data: {
			type: Array,
			default: function () {
				return new Array
			}
		},
		integral_rank_data: {
			type: Array,
			default: function () {
				return new Array
			}
		}
	},

	components: {
		RankingMoney,
		RankingIntegral
	},
	data() {
		return {
			changeSearch: this.search,
		}
	},
	methods: {
		leftChange(e) {
			this.$emit('leftChange', e)
		},
		updateBalanceData(value) {

			this.changeSearch.balance_time_type = value.balance_time_type;
			let data = {
				...value,
				integral_time_type: this.changeSearch.integral_time_type
			}
			this.$emit('updateData', data);
		},


		updateIntegralData(value) {
			this.changeSearch.integral_time_type = value.change_integral_time_type;

			let data = {
				...value,
				balance_time_type: this.changeSearch.balance_time_type
			}
			console.log(data, 'value');
			this.$emit('updateData', data);
		}
	}
}
</script>

<style>
.ranking-box {
	width: 100%;
	min-height: 600px;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 24px;
	line-height: 1;
}
</style>