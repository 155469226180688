<template>
	<div class="home-page" style="padding: 30px 20px;">
		<a-spin :spinning="loading">
			<!-- <div class="home-page-header flex alcenter  mb30">
					<span class="home-notice-icon"><i class="iconfont iconnotice1 ft12"></i></span>
					<div class="ft14 ftw400 cl-main ml10" v-if="shop.is_expire==0">您的店铺为{{shop.type==1 ? '试用版' : '正式版'}}，有效期至：</div>
					<div class="ft14 ftw400 cl-main ml10" v-if="shop.is_expire==1">您的店铺服务到期，已打烊。如需恢复正常营业，请订购正式版。</div>
					<div class="ft14 ftwblod cl-price ml10" v-if="shop.is_expire==0">{{shop.expire_time_format}}</div>
					<div class="ml10">
						<a-button type="primary" style="border-radius: 20px;" @click="renewAct()">立即续费</a-button>
					</div>
			</div> -->
			<div>
				<div>
					<home-count :kefu="kefu" :balance_recharge_data="balance_recharge_data"
						:balance_consume_data="balance_consume_data" :coupon_consume_data="coupon_consume_data"></home-count>
				</div>

				<div class="mt30">
					<home-ranking @leftChange="Pagechange" :integral_members_count="integral_members_count"
						:balance_members_count="balance_members_count" :search="search" @updateData="updateData"
						:balance_rank_data="balance_rank_data" :integral_rank_data="integral_rank_data"></home-ranking>
				</div>
			</div>

			<div v-if="renewLoading">
				<re-new :visible="renewLoading" @cancel="cancelRenew" @ok="okRenew"></re-new>
			</div>
		</a-spin>
	</div>
</template>

<script>
import homeCount from './components/Count.vue';
import homeRanking from './components/Ranking.vue';
import reNew from './components/index/modal/renew.vue';
export default {
	components: {
		homeCount,
		homeRanking,
		reNew,
	},
	data() {
		return {
			balance_members_count: 0,
			integral_members_count: 0,
			loading: false,
			status: 1,
			renewLoading: false,
			queryPage: {
				pagt: 1,
				limit: 10
			},
			shop: {
				type: 1,
				expire_time_format: '',
				is_expire: 1,
			},
			search: {
				balance_time_type: 1,
				integral_time_type: 1,
				begin_time1: '',
				end_time1: '',
				begin_time2: '',
				end_time2: ''
			},
			kefu: {
				name: '小二',
				qrcode: null,
				mobile: '15000000000',
			},
			balance_recharge_data: {
				total_recharge_balance: 0,
				total_recharge_give_balance: 0,
				today_recharge_balance: 0,
				today_recharge_give_balance: 0,
				this_month_recharge_balance: 0,
				this_month_recharge_give_balance: 0,
			},
			// balance_consume_data:{
			// 	total_consume_balance:0,
			// 	today_consume_balance:0,
			// 	this_month_consume_balance:0
			// },
			// coupon_consume_data:{
			// 	total_consume_coupon:0,
			// 	today_consume_coupon:0,
			// 	this_month_consume_coupon:0,
			// },
			balance_rank_data: [],
			integral_rank_data: [],
		}
	},
	created() {
		this.loaddata();
	},
	methods: {
		Pagechange(e) {

			this.queryPage.page = e
			this.loaddata()
		},
		loaddata() {

			if (this.loading == true) return;
			this.loading = true;
			this.$http.api('admin/home', {
				// balance_time_type: this.search.balance_time_type,
				// integral_time_type: this.search.integral_time_type,
				...this.search,
				...this.queryPage
			}).then(res => {
				// console.log(res.balance_members_count, 'res');
				this.balance_members_count = res.balance_members_count
				this.integral_members_count = res.integral_members_count

				this.shop = res.shop;
				this.kefu = res.kefu;
				this.balance_recharge_data = res.balance_recharge_data;
				// this.balance_consume_data=res.balance_consume_data;
				// this.coupon_consume_data=res.coupon_consume_data;
				this.balance_rank_data = res.balance_rank_data;
				this.integral_rank_data = res.integral_rank_data;
				this.loading = false;
			}).catch(res => {
				console.log(res);
				this.loading = false;
			})
		},
		updateData(value) {
			console.log(value, 'updateData');
			this.search = {
				balance_time_type: 1,
				integral_time_type: 1,
				begin_time1: '',
				end_time1: '',
				begin_time2: '',
				end_time2: ''
			}

			this.search.balance_time_type = value.balance_time_type;
			this.search.integral_time_type = value.integral_time_type;
			if (value.type == 1) {
				this.search.begin_time1 = value.begin_time1
				this.search.end_time1 = value.end_time1
			} else {
				this.search.begin_time2 = value.begin_time2
				this.search.end_time2 = value.end_time2
			}
			console.log(this.search);


			this.loaddata();
		},

		renewAct() {
			this.renewLoading = true;
		},
		cancelRenew() {
			this.renewLoading = false;
		},
		okRenew() {
			this.renewLoading = false;
			this.loaddata();
		},
	},
}
</script>

<style>
.home-page {
	min-width: 1280px;
}

.home-page-header {
	height: 60px;
	width: 100%;
	background: #FFFFFF;
	border-radius: 8px;
	padding: 0 20px;
}

.home-notice-icon {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	text-align: center;
	line-height: 24px;
	background: rgba(255, 153, 0, 0.1);
	color: rgba(255, 153, 0, 1);
}

.kefu-box {
	width: 220px;
	height: 260px;
	background: #FFFFFF;
	border-radius: 8px;
	overflow: hidden;
	line-height: 1;
}

.kefu-box-top {
	width: 100%;
	height: 188px;
	padding: 20px 20px 16px 20px;
}

.kefu-box-bottom {
	width: 100%;
	height: 72px;
	position: relative;
}

.kefu-box-bottom .main {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 72px;
	padding: 15px 20px;
}

.kefu-box-bottom .bg {
	width: 100%;
	height: 82px;
}

.qrcode-box {
	width: 220px;
	background: #FFFFFF;
	border-radius: 8px;
	overflow: hidden;
	padding: 20px;
	line-height: 1;
}

.mendian-status-box {
	width: 220px;
	background: #FFFFFF;
	border-radius: 8px;
	overflow: hidden;
	padding: 20px;
	line-height: 1;
}
</style>
